import React from 'react'
import '../styles/header.scss'
import Layout from "../components/layout";

const Affiliate = () => (
    <Layout>
    <p>affiliate</p>
    </Layout>
);

export default Affiliate
